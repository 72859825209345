import { Divider, Flex, HStack, Button, Skeleton } from "@chakra-ui/react";
import { useContext } from "react";
import BreadCrumb from "../components/BreadCrumb";
import NewFolder from "../components/NewFolder";
import UploadButton from "../components/UploadButton";
import StorageTable from "../components/StorageTable";
import PageSwitcher from "../components/PageSwitcher";
import { AppContext } from "../context/AppContext";
import ViewFile from "../components/ViewFile";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const Home = () => {
  const { listLoading, fileId, findPreviousFile, findNextFile, filesInPage } =
    useContext(AppContext);

  const currentFileIdx = filesInPage.findIndex((item) => item === fileId);

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <BreadCrumb />
        {fileId && (
          <HStack>
            <Button
              isDisabled={currentFileIdx === 0}
              onClick={findPreviousFile}
              size="sm"
            >
              <ArrowBackIcon />
            </Button>
            <Button
              isDisabled={currentFileIdx === filesInPage?.length - 1}
              onClick={findNextFile}
              size="sm"
            >
              <ArrowForwardIcon />
            </Button>
          </HStack>
        )}
      </Flex>
      <Divider my={"16px"}></Divider>
      {fileId ? (
        <ViewFile isLoading={listLoading} />
      ) : (
        <>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            my={"12px"}
          >
            <Skeleton isLoaded={!listLoading}>
              <PageSwitcher />
            </Skeleton>
            <Skeleton isLoaded={!listLoading}>
              <HStack>
                <NewFolder />
                <UploadButton />
              </HStack>
            </Skeleton>
          </Flex>
          <StorageTable isLoading={listLoading} />
        </>
      )}
    </>
  );
};

export default Home;
