import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ACCESS_TOKEN_STRING = "access_token";
const ACCESS_TOKEN_HEADER_STRING = "x-access-token";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const networkErrorLogger = (e, URL, PAYLOAD, isAuth = true) => {
  console.log(
    `Request: ${URL} with payload: ${JSON.stringify(PAYLOAD)} failed! `
  );

  if (e?.message === "Network Error") {
    throw new Error("Network Error. Ensure you are connected to internet.");
  } else if (isAuth && e?.response?.status === 401) {
    window.localStorage.removeItem(ACCESS_TOKEN_STRING);
    window.location.reload();
  } else {
    throw e;
  }
};

const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_STRING);

const setUpConfig = (isAuth) => {
  const DEFAULT_CONFIG = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (isAuth) {
    DEFAULT_CONFIG.headers[ACCESS_TOKEN_HEADER_STRING] = getAccessToken();
    return DEFAULT_CONFIG;
  } else {
    return DEFAULT_CONFIG;
  }
};

const get = async (URL, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.get(URL, CONFIG);

    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, "nil", isAuth);
  }
};

const post = async (URL, PAYLOAD = {}, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.post(URL, PAYLOAD, CONFIG);
    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, PAYLOAD, isAuth);
  }
};

const _delete = async (URL, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.delete(URL, CONFIG);
    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, null, isAuth);
  }
};

export const createFile = async (data, options) => {
  const URL = API_BASE_URL + `/file`;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        [ACCESS_TOKEN_HEADER_STRING]: getAccessToken(),
      },
      ...options,
    };

    const result = await axiosInstance.post(URL, data, config);

    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, data);
  }
};

export const login = (PAYLOAD) => {
  const URL = API_BASE_URL + `/users/login`;
  return post(URL, PAYLOAD, false);
};

export const list = (page = "0", size = 20, folder, keyword) => {
  const URL =
    API_BASE_URL +
    `?size=${size}&page=${page}${folder ? `&folder=${folder}` : ""}${
      keyword ? `&keyword=${keyword}` : ""
    }`;
  return get(URL);
};

export const createFolder = (folderName, folderPath) => {
  const URL = API_BASE_URL + `/folder`;
  return post(URL, { folder: folderPath, name: folderName });
};

export const deleteFile = (fileId) => {
  const URL = API_BASE_URL + `/${fileId}`;
  return _delete(URL);
};
