import { Skeleton, Tbody, Td, Tr } from "@chakra-ui/react";

const TableSkeleton = () => (
  <Tbody>
    {[1, 2, 3, 4].map((i) => (
      <Tr key={i}>
        <Td padding={"16px 12px"}>
          <Skeleton height="25px" />
        </Td>
        <Td padding={"16px 12px"}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

export default TableSkeleton;
