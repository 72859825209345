import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { login } from "../api";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const { checkAuthState, logInUser } = useContext(AuthContext);

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const togglePasswordVisibility = () => setShow(!show);
  const toast = useToast();

  const _validate = (email, password) => {
    if (!email?.trim() || !password) {
      toast({
        title: "Error",
        description: "Please input all the fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      _login(email?.trim(), password);
    }
  };

  const _login = async (email, password) => {
    try {
      const PAYLOAD = { email, password };
      setLoginButtonLoading(true);
      const res = await login(PAYLOAD);

      if (res?.token) {
        logInUser(res?.token);
      }
    } catch (e) {
      toast({
        title: "Error",
        description:
          e?.response?.data?.error ?? "Something went wrong, please try again",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoginButtonLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    _validate(email, password);
  };

  return (
    <Box px={10}>
      <Center sx={{ height: "80vh" }}>
        <Box
          shadow={"md"}
          p={10}
          border="1px"
          borderColor="gray.200"
          borderRadius="5px"
        >
          <Center mb={5}>
            <LockIcon mr={1} />
            <Heading as="b" size="md">
              Log In
            </Heading>
          </Center>
          <form onSubmit={onSubmit}>
            <FormControl id="username" mb={5}>
              <FormLabel>Email</FormLabel>
              <Input
                type={"text"}
                placeholder="Enter email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" mb={5}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <Button onClick={togglePasswordVisibility}>
                    {show ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Center>
              <VStack>
                <Button
                  colorScheme="green"
                  isLoading={loginButtonLoading}
                  type="submit"
                >
                  Login
                </Button>
              </VStack>
            </Center>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default Login;
