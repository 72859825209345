import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  chakra,
  HStack,
  Flex,
  useColorModeValue,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { IoMdPower } from "react-icons/io";
import { FcAddDatabase } from "react-icons/fc";
import ToggleTheme from "../components/ToggleTheme";
import { AuthContext } from "../context/AuthContext";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import Package from "../../package.json";

const Header = () => {
  const { isLoggedIn, logOutUser } = useContext(AuthContext);
  const bg = useColorModeValue("white", "gray.800");
  const version = Package.version;

  return (
    <chakra.header
      shadow={"sm"}
      transition="box-shadow 0.2s"
      pos="fixed"
      top="0"
      zIndex="3"
      left="0"
      right="0"
      bg={bg}
      width="full"
      py="8px"
    >
      <Flex
        px={"80px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"56px"}
      >
        <Link to={"/"}>
          <Tooltip label={`v${version}`}>
            <HStack>
              <FcAddDatabase size={"26px"} />
              <Heading size={"md"}>File Store</Heading>
            </HStack>
          </Tooltip>
        </Link>
        <HStack>
          {isLoggedIn && <SearchBar />}
          <ToggleTheme />
          {isLoggedIn && <LogOutButton logOutUser={logOutUser} />}
        </HStack>
      </Flex>
    </chakra.header>
  );
};

const LogOutButton = ({ logOutUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} size="md">
        <IoMdPower />
      </Button>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Log out
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              Are you sure you want to Log out?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose} size="sm">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => {
                  onClose();
                  logOutUser();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Header;
