import {
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useColorMode,
  Heading,
  VStack,
  HStack,
} from "@chakra-ui/react";
import TableSkeleton from "./TableSkeleton";
import { FcFolder, FcFile } from "react-icons/fc";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const StorageTable = ({ isLoading }) => {
  const { colorMode } = useColorMode();
  const { navigate, requestSort, sortConfig, list, totalCount, pageSize } =
    useContext(AppContext);

  if (isLoading || list?.length) {
    return (
      <Table mt={"12px"} size="md">
        {!isLoading && (
          <TableCaption mt={"8px"} placement="top">
            {`${
              totalCount > pageSize ? `${pageSize} of ` : ""
            }${totalCount} item${totalCount > 1 ? "s" : ""}`}
          </TableCaption>
        )}
        <Thead>
          <Tr>
            <Th
              onClick={() => requestSort("name")}
              width={"65%"}
              cursor={"pointer"}
              padding={"0px"}
              _active={{ transform: "translateY(-1px)" }}
            >
              <HStack padding={"16px 12px"}>
                <Text>Name</Text>
                {sortConfig?.key === "name" &&
                  (sortConfig?.direction === "asc" ? (
                    <FaSortDown />
                  ) : sortConfig?.direction === "desc" ? (
                    <FaSortUp />
                  ) : null)}
              </HStack>
            </Th>
            <Th
              onClick={() => requestSort("updatedAt")}
              width={"35%"}
              cursor={"pointer"}
              padding={"0px"}
              _active={{ transform: "translateY(-1px)" }}
            >
              <HStack padding={"16px 12px"}>
                <Text>Last Modified</Text>
                {sortConfig?.key === "updatedAt" &&
                  (sortConfig?.direction === "asc" ? (
                    <FaSortDown />
                  ) : sortConfig?.direction === "desc" ? (
                    <FaSortUp />
                  ) : null)}
              </HStack>
            </Th>
          </Tr>
        </Thead>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Tbody>
            {list.map((item) => (
              <Tr
                _hover={{ bg: colorMode === "light" ? "gray.50" : "#141214" }}
                key={item._id}
                cursor={"pointer"}
              >
                <Td
                  display={"flex"}
                  alignItems={"center"}
                  padding={"16px 12px"}
                  _hover={{ textDecoration: "underline" }}
                  onClick={() => navigate(item)}
                >
                  {item.is_folder ? (
                    <FcFolder size={"18px"} />
                  ) : (
                    <FcFile size={"18px"} />
                  )}
                  <Text ml={"8px"}>{item.name}</Text>
                </Td>
                <Td padding={"16px 12px"}>{item.updatedAt}</Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    );
  } else {
    return (
      <VStack height={"50vh"} justifyContent="center">
        <Heading fontSize={"16px"} fontWeight={"semibold"}>
          This folder is empty
        </Heading>
        <Text fontSize={"14px"}>
          Start by creating a folder or uploading a file
        </Text>
      </VStack>
    );
  }
};

export default StorageTable;
