import { Button, useColorMode, Tooltip } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

const ToggleTheme = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Tooltip
      hasArrow
      label={`Switch to ${colorMode === "light" ? "dark" : "light"} mode`}
      aria-label={`Switch to ${colorMode === "light" ? "dark" : "light"} mode`}
    >
      <Button onClick={toggleColorMode} size="md">
        {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
      </Button>
    </Tooltip>
  );
};

export default ToggleTheme;
