import React, { createContext, useState } from "react";

const initialAuthState = {
  access_token: "",
};

const ACCESS_TOKEN_STRING = "access_token";

export const AuthContext = createContext({
  authState: initialAuthState,
});

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialAuthState);

  const updateAuthStateContext = (access_token) => {
    window.localStorage.setItem(ACCESS_TOKEN_STRING, access_token);
    setAuthState({
      access_token,
    });
  };

  const logOutUser = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_STRING);
    setAuthState({
      ...initialAuthState,
    });
  };

  const checkAuthState = () => {
    const access_token = window.localStorage.getItem(ACCESS_TOKEN_STRING);
    if (!access_token) {
      logOutUser();
    } else {
      logInUser(access_token);
    }
  };

  const logInUser = (access_token) => {
    updateAuthStateContext(access_token);
  };

  const isLoggedIn = authState.access_token !== "";

  const value = {
    authState,
    isLoggedIn,
    checkAuthState,
    logInUser,
    logOutUser,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
