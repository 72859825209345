import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { Button, HStack, Select } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const PageSwitcher = () => {
  const {
    page,
    setPage,
    pageSize,
    changePageSize,
    totalCount,
    pageSizeOptions,
    totalPages,
  } = useContext(AppContext);

  const showLeftPageButton = page - 1 > 0;
  const showRightPageButton = page + 1 <= totalPages;

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  return totalCount > 0 ? (
    <HStack spacing={2}>
      <Button onClick={() => setPage(1)} isDisabled={page === 1} size="sm">
        <ArrowLeftIcon />
      </Button>
      <Button onClick={previousPage} isDisabled={page === 1} size="sm">
        <ArrowBackIcon />
      </Button>
      {showLeftPageButton && (
        <Button
          onClick={() => {
            setPage(page - 1);
          }}
          size="sm"
        >
          {page - 1}
        </Button>
      )}
      <Button isDisabled={true} colorScheme="green" size="sm">
        {page}
      </Button>
      {showRightPageButton && (
        <Button onClick={() => setPage(page + 1)} size="sm">
          {page + 1}
        </Button>
      )}
      <Button onClick={nextPage} isDisabled={page === totalPages} size="sm">
        <ArrowForwardIcon />
      </Button>
      <Button
        onClick={() => setPage(totalPages)}
        isDisabled={page === totalPages}
        size="sm"
      >
        <ArrowRightIcon />
      </Button>
      <Select
        width={"80px"}
        variant={"filled"}
        value={pageSize}
        cursor={"pointer"}
        onChange={(e) => changePageSize(parseInt(e.target.value))}
      >
        {pageSizeOptions.map((val) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </Select>
    </HStack>
  ) : (
    <></>
  );
};

export default PageSwitcher;
