import { SearchIcon } from "@chakra-ui/icons";
import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Divider,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { FcFile, FcFolder } from "react-icons/fc";
import { AiOutlineEnter } from "react-icons/ai";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const SearchBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    searchText,
    setSearchText,
    searchLoading,
    searchList,
    setSearchList,
    navigate,
  } = useContext(AppContext);

  const onSearchOpen = (e) => {
    e.preventDefault();
    setSearchList([]);
    onOpen();
  };

  const onSearchClose = () => {
    setSearchText("");
    onClose();
  };

  return (
    <>
      <HStack>
        <InputGroup size={"md"} width={"35vw"} cursor={"pointer"}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            placeholder="Search by folder, file name or tags"
            onClick={onSearchOpen}
            onChange={onSearchOpen}
          />
        </InputGroup>
      </HStack>
      <Modal isOpen={isOpen} onClose={onSearchClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <InputGroup size={"md"} cursor={"pointer"}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search by folder, file name or tags"
              />
              {searchText && searchLoading && (
                <InputRightElement
                  pointerEvents="none"
                  children={<Spinner size="sm" />}
                  mr={2}
                />
              )}
            </InputGroup>
          </ModalHeader>
          {searchText && searchList?.length ? (
            <ModalBody
              display={"flex"}
              flexDirection={"column"}
              maxHeight={"60vh"}
              overflowY={"scroll"}
            >
              <Divider mb={4} />
              {searchList.map((item) => (
                <Button
                  key={item?._id}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  padding={"14px 20px"}
                  height={"65px"}
                  width={"100%"}
                  _hover={{ textDecoration: "underline" }}
                  mb={"16px"}
                  onClick={() => {
                    navigate(item);
                    onSearchClose();
                  }}
                >
                  <Flex direction={"column"} alignItems={"flex-start"}>
                    <Text
                      _hover={{ textDecoration: "none" }}
                      fontSize={"11px"}
                      opacity={0.8}
                      mb={"8px"}
                    >
                      Home&nbsp;&gt;&nbsp;{item?.path?.join(" > ")}
                    </Text>
                    <HStack>
                      {item.is_folder ? (
                        <FcFolder size={"18px"} />
                      ) : (
                        <FcFile size={"18px"} />
                      )}
                      <Text ml={"8px"}>{item.name}</Text>
                    </HStack>
                  </Flex>
                  <AiOutlineEnter size={"18px"} />
                </Button>
              ))}
            </ModalBody>
          ) : null}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchBar;
