import {
  Flex,
  HStack,
  useToast,
  Grid,
  GridItem,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Image,
  Badge,
  Table,
  Tr,
  Td,
  Tbody,
  Tooltip,
  Skeleton,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import dayjs from "dayjs";
import { MdDeleteOutline, MdDownload } from "react-icons/md";
import { FcFile } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { deleteFile } from "../api";
import fileTypes from "../constants/fileTypes";

const ViewFile = ({ isLoading }) => {
  const toast = useToast();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const history = useHistory();
  const { path, _getList, fileDetails } = useContext(AppContext);

  const fileUrl = new URL(fileDetails?.location) ?? "";
  const splittedFileName = fileUrl.pathname.split(".");
  const fileExtension = () => {
    let fileExtensionn = splittedFileName[splittedFileName.length - 1];
    if (fileExtensionn === "CR2") {
      return "jpg";
    } else {
      return fileExtensionn
    }
  };

  const fileTypee = fileExtension();
  const fileType = fileTypes[fileTypee] ?? "na";


  const errorToast = (message) =>
    toast({
      title: "Error",
      description: message ?? "Something went wrong, please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const deleteAction = async () => {
    try {
      setDeleteLoading(true);
      await deleteFile(fileDetails?._id);
    } catch (e) {
      console.log(e?.response ?? e);
      errorToast(e?.response?.data?.error);
    } finally {
      setDeleteLoading(false);
      if (path?.trim()) {
        history.goBack();
        _getList();
      }
    }
  };

  const downloadFile = (e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.download = fileDetails?.name;
    link.href = fileDetails?.location;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid templateColumns="0.65fr 0.35fr" gap={"20px"} mt={"24px"}>
      <Skeleton isLoaded={!isLoading}>
        <GridItem
          w={"100%"}
          h={"65vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {fileType === "image" && (
            <Image
              loading={"lazy"}
              maxWidth={"100%"}
              maxHeight={"100%"}
              src={fileDetails?.previewlocation}
              alt={"File preview"}
              objectFit="cover"
            />
          )}
          {fileType === "video" && (
            <Box
              as="video"
              controls
              maxWidth={"100%"}
              maxHeight={"100%"}
              src={fileDetails?.location}
              alt="Video playback"
              objectFit="contain"
              sx={{
                aspectRatio: "16/9",
              }}
            />
          )}
          {fileType === "audio" && (
            <Box
              as="audio"
              controls
              src={fileDetails?.location}
              alt="Audio playback"
            />
          )}
          {fileType === "na" && (
            <VStack>
              <FcFile size={"65px"} />
              <Badge>Preview not available</Badge>
            </VStack>
          )}
        </GridItem>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        <GridItem
          w={"100%"}
          h={"65vh"}
          display={"flex"}
          flexDirection={"column"}
          borderWidth={"2px"}
          borderRadius={"3px"}
          padding={"24px"}
          overflowY={"scroll"}
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Badge fontSize={"md"}>Info</Badge>
            <HStack>
              <DeleteFileButton
                onDelete={deleteAction}
                isLoading={deleteLoading}
              />
              <Tooltip label="Download" aria-label="Download">
                <Button size="sm" onClick={downloadFile}>
                  <MdDownload size={"18px"} />
                </Button>
              </Tooltip>
            </HStack>
          </Flex>
          <Table variant="unstyled" mt={"16px"}>
            <Tbody>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Name
                </Td>
                <Td verticalAlign={"initial"} p={"8px"}>
                  {fileDetails?.name}
                </Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Description
                </Td>
                <Td verticalAlign={"initial"} p={"8px"}>
                  {fileDetails?.description}
                </Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Short Description
                </Td>
                <Td verticalAlign={"initial"} p={"8px"}>
                  {fileDetails?.short_description}
                </Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Tags
                </Td>
                <Td p={"8px"}>{fileDetails?.tags?.join(", ")}</Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Uploaded at
                </Td>
                <Td p={"8px"}>
                  {dayjs(fileDetails?.createdAt).format("MM/DD/YYYY h:mm a")}
                </Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign={"initial"}
                  p={"8px 8px 8px 0px"}
                  width={"35%"}
                  color={"gray.500"}
                >
                  Modified at
                </Td>
                <Td p={"8px"}>
                  {dayjs(fileDetails?.updatedAt).format("MM/DD/YYYY h:mm a")}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </GridItem>
      </Skeleton>
    </Grid>
  );
};

const DeleteFileButton = ({ onDelete, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const deleteClick = () => {
    onClose();
    onDelete();
  };

  return (
    <>
      <Tooltip label="Delete file" aria-label="Delete file">
        <Button
          isLoading={isLoading}
          color="red"
          onClick={() => setIsOpen(true)}
          size="sm"
        >
          <MdDeleteOutline size={"18px"} />
        </Button>
      </Tooltip>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Delete File
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              Are you sure you want to delete this file?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose} size="sm">
                Cancel
              </Button>
              <Button colorScheme="red" size="sm" onClick={deleteClick} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ViewFile;

