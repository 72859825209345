const fileTypes = {
  jpg: "image",
  jpeg: "image",
  gif: "image",
  png: "image",
  mp4: "video",
  mkv: "video",
  mp3: "audio",
};

export default fileTypes;
