import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Text,
  FormErrorMessage,
  FormHelperText,
  Grid,
  CloseButton,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { FcFile, FcUpload } from "react-icons/fc";
import { useCallback, useState, useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Center, useColorModeValue, Icon } from "@chakra-ui/react";
import { AppContext } from "../context/AppContext";
import { createFile } from "../api";

const UploadButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { _getList, path } = useContext(AppContext);
  const toast = useToast();

  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [shortDescError, setShortDescError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const validate = () => {
    const _descError = description?.trim()?.length === 0;
    const _shortDescError = shortDescription?.trim()?.length === 0;
    const _tagError = tags?.trim()?.length === 0;
    const _fileError = !file;

    setFileError(_fileError);
    setDescError(_descError);
    setShortDescError(_shortDescError);
    setTagError(_tagError);

    return _descError || _fileError || _shortDescError || _tagError;
  };

  const errorToast = (message) =>
    toast({
      title: "Error",
      description: message ?? "Something went wrong, please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const successToast = (message) =>
    toast({
      title: "Done",
      description: message ?? "Action is completed",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

  const uploadFile = async (e) => {
    e.preventDefault();

    const error = validate();

    if (error) return;

    setLoading(true);

    try {
      const data = new FormData();

      const tagsFormatted = tags
        .split(",")
        .map((tag) => tag.trim())
        .join(",");

      data.append("file", file);
      data.append("tags", tagsFormatted);
      data.append("description", description);
      data.append("short_description", shortDescription);
      data.append("folder", path);

      const options = {
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadPercent(percent);
        },
      };

      await createFile(data, options);

      setLoading(false);
      onClose();
      successToast("File uploaded");
    } catch (e) {
      setLoading(false);
      console.log(e?.response ?? e);
      onClose();
      errorToast(e?.response?.data?.error);
    } finally {
      _getList();
    }
  };

  const fileSize = useMemo(
    () =>
      file?.size / Math.pow(1024, 1) > 999
        ? (file?.size / Math.pow(1024, 2))?.toFixed(2) + " MB"
        : (file?.size / Math.pow(1024, 1))?.toFixed(2) + " KB",
    [file]
  );

  return (
    <>
      <Button
        width={"140px"}
        onClick={onOpen}
        leftIcon={<FcUpload size={"16px"} />}
      >
        Upload
      </Button>
      <Modal
        scrollBehavior={"inside"}
        size={"lg"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height={"90vh"}>
          <ModalHeader>Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={2} isInvalid={shortDescError}>
              <FormLabel>Short Description</FormLabel>
              <Input
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                placeholder="Describe.."
                autoFocus
              />
              <FormErrorMessage>Short description is required</FormErrorMessage>
            </FormControl>
            <FormControl mb={2} isInvalid={descError}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe.."
              />
              <FormErrorMessage>Description is required</FormErrorMessage>
            </FormControl>
            <FormControl mb={2} isInvalid={tagError}>
              <FormLabel>Tags</FormLabel>
              <Input
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Torcue, JPEG, Digital"
              />
              {tagError ? (
                <FormErrorMessage>Tags are required</FormErrorMessage>
              ) : (
                <FormHelperText>Enter tags as comma seperated</FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={fileError}>
              <FormLabel>Select file</FormLabel>
              {file ? (
                <Grid
                  templateColumns={"0.08fr 0.7fr 0.2fr 0.05fr"}
                  gap={"8px"}
                  borderWidth={"1px"}
                  borderRadius={"5px"}
                  padding={"8px 12px"}
                  width={"100%"}
                  alignItems={"center"}
                >
                  <Icon size={"16px"} as={FcFile} />
                  <Text>{file?.name}</Text>
                  <Text fontSize={"14px"} opacity={"0.8"}>
                    {fileSize}
                  </Text>
                  <CloseButton
                    variant={"outline"}
                    onClick={() => setFile(null)}
                  />
                </Grid>
              ) : (
                <FileUpload setFile={setFile} />
              )}
              <FormErrorMessage>File is required</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} alignItems={"center"}>
            {loading ? (
              <Progress
                width={"70%"}
                colorScheme="green"
                size="sm"
                value={uploadPercent}
              />
            ) : (
              <div></div>
            )}
            <Button isLoading={loading} onClick={uploadFile}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const FileUpload = ({ setFile }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  const dropText = isDragActive
    ? "Drop the file here.."
    : "Click to select file";

  const activeBg = useColorModeValue("gray.100", "gray.600");
  const borderColor = useColorModeValue(
    isDragActive ? "teal.300" : "gray.300",
    isDragActive ? "teal.500" : "gray.500"
  );

  return (
    <Center
      p={10}
      cursor="pointer"
      bg={isDragActive ? activeBg : "transparent"}
      _hover={{ bg: activeBg }}
      transition="background-color 0.2s ease"
      borderRadius={4}
      border="3px dashed"
      borderColor={borderColor}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon size={"16px"} as={FcFile} mr={2} />
      <Text color={"gray.500"}>{dropText}</Text>
    </Center>
  );
};

export default UploadButton;
